//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    XModel: () => import("@/components/XModal"),
    mb: () => import("@/components/Selectors/MemberSelectCopy"),
  },
  props: {
    //规则版本信息
    selRow: {
      type: Object,
      default: null,
    },
    teamValue: {
      type: Number,
      default: null,
    },
    operationName: {
      type: String | Number,
      default: 1,
    },
    activeItem: {
      type: String,
      default: "部门",
    },
    id: {
      type: String | Number,
      default: "",
    },
  },
  data() {
    return {
      loadForm: {
        word: [undefined, undefined, undefined, undefined, undefined],
        pn: [undefined, undefined, undefined, undefined, undefined],
        rType: 1,
        mk2: "未知",
        mk1: "未知",
        g: [],
        radio: false,
        Automatic: false,
        RuleName: "",
        member: [],
      },
      processOptions: [],
      user: [],
      copyForm: "",
      subLoading: false,
      loading: false,
    };
  },
  methods: {
    getUser(arr) {
      this.loadForm.member = arr;
    },
    async GetProgressGroup() {
      const resp = await this.$http.post(
        "/User/GetProgressGroupSelected.ashx",
        { teamId: this.teamValue, configId: this.selRow.Id }
      );
      if (resp.res == 0) {
        this.processOptions = resp.data;
      }
    },
    beforeOpen() {
      this.loading = false;
      this.GetProgressGroup();
      if (this.operationName == 2) {
        this.getDetail();
      }
    },
    getDetail() {
      this.loading = true;
      this.$http
        .get("/ProcessRules/ProcessRulesDetail.ashx", {
          params: { ruleId: this.id, teamId: this.teamValue },
        })
        .then((res) => {
          if (res.res == 0) {
            this.loading = false;
            if (this.activeItem == "部门") {
              this.loadForm.rType = 1;
              this.loadForm.g = res.data.PreocessGroup.map((m) => m.ID);
            } else {
              this.loadForm.rType = 2;
              this.user = [];
              this.loadForm.member = [];
              res.data.UserData.forEach((item) => {
                this.loadForm.member.push({
                  Name: item.Name,
                  UsId: item.UsId,
                });
              });
            }
            if (res.data.ProgressNames && res.data.ProgressNames.length) {
              res.data.ProgressNames.forEach((item, index) => {
                this.loadForm.pn[index] = item;
              });
              this.loadForm.radio = false;
            } else {
              this.loadForm.radio = true;
            }
            if (res.data.FormNames && res.data.FormNames.length) {
              res.data.FormNames.forEach((item, index) => {
                this.loadForm.word[index] = item;
              });
            }
            this.loadForm.RuleName = res.data.RuleName;
            this.loadForm.mk1 =
              res.data.MarkInCheck == "上班" ? "工作" : res.data.MarkInCheck;
            this.loadForm.mk2 =
              res.data.MarkOutCheck == "上班" ? "工作" : res.data.MarkOutCheck;
            this.loadForm.Automatic = res.data.Automatic;
            this.loadForm.t = this.activeItem;
            this.copyForm = JSON.stringify(this.loadForm);
            Object.assign({}, this.loadForm);
          }
        });
    },
    init() {
      Object.assign(this.$data, this.$options.data());
    },
    addMore(type) {
      if (type == 1) {
        let arr = this.loadForm.pn.filter((item) => {
          return item == undefined || "";
        });
        if (!arr.length) {
          this.loadForm.pn.unshift(undefined);
        }
      } else {
        let arr = this.loadForm.word.filter((item) => {
          return item == undefined || "";
        });
        if (!arr.length) {
          this.loadForm.word.unshift(undefined);
        }
      }
    },
    filterParams(params) {
      let _params = { ...params };

      for (let key in _params) {
        if (_params[key] instanceof Array) {
          _params[key] = JSON.stringify(_params[key]);
        }
      }
      return _params;
    },
    check() {},
    //提交
    submit() {
      if (this.subLoading) {
        return;
      }
      let bool = this.copyForm == JSON.stringify(this.loadForm);
      if (bool && this.operationName == 2) {
        this.$modal.hide("ruleXmodal");
        return;
      } else {
        let word = [];
        this.loadForm.word.forEach((item) => {
          //关键词
          if (item) {
            word.push(item);
          }
        });
        let pn = [];
        this.loadForm.pn.forEach((item) => {
          //进程名
          if (item) {
            pn.push(item);
          }
        });
        let flag = false;
        let str = "";
        if (!this.loadForm.radio && pn.length == 0) {
          flag = true;
          str = "请至少输入一个进程名称";
        }
        if (this.loadForm.g.length == 0 && this.rType == 1) {
          flag = true;
          str = "请选择作用部门";
        }
        if (this.loadForm.member.length == 0 && this.rType == 2) {
          flag = true;
          str = "请选择成员";
        }
        if (!this.loadForm.RuleName) {
          flag = true;
          str = "请输入规则名称";
        }
        if (flag) {
          this.$message({
            type: "warning",
            message: str,
          });
          return;
        }

        this.subLoading = true;
        let params = {
          Id: this.id,
          RuleName: this.loadForm.RuleName,
          ProgressGroupIds: this.loadForm.rType == 1 ? this.loadForm.g : [],
          UserIds:
            this.loadForm.rType == 2
              ? this.loadForm.member.map((item) => item.UsId)
              : [],
          ProgressNames: this.loadForm.radio ? [] : pn,
          FormNames: word,
          MarkOutCheck: this.loadForm.mk2,
          MarkInCheck: this.loadForm.mk1,
          Automatic: this.loadForm.Automatic,
          teamId: this.teamValue,
          configId: this.selRow.Id,
        };
        params = this.filterParams(params);
        this.$http
          .get("/ProcessRules/SaveProcessRules.ashx", {
            params,
          })
          .then((res) => {
            this.subLoading = false;
            if (res.res == 0) {
              this.$modal.hide("ruleXmodal");
              this.$emit("update");
              this.$message({
                type: "success",
                message: this.operationName == 1 ? "添加成功" : "修改成功",
              });
            }
          });
      }
    },
  },
  mounted() {},
  created() {},
};
